import config from '/config';
import request from '@wearetla/tla-essentials-tools/utilities/request';

// Functions
import formatPhone from '@wearetla/tla-essentials-tools/functions/format-phone'

const saleServices = {
	addPromotionCode : (code) => {
		return new Promise((resolve, reject) => {
			request.post('promotion/add', { promotion_code: code }, function (payload, status, request) {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Hediye kodu eklenirken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
				}
			});
		});
	},
	removePromotionCode : () => {
		return new Promise((resolve, reject) => {
			request.delete('promotion/remove', false, function (payload, status, request) {
				if (status === 200) {
					resolve(payload);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Hediye kodu kaldırılırken bir hata ile karşılaşıldı. Lütfen tekrar deneyin.');
				}
			});
		});
	},
	prepare: (params = {}) => {
		return new Promise((resolve, reject) => {
			request.get('sale/prepare', params, function (payload, status, request) {
				if (status === 200 && payload && payload.sale && payload.sale.products && payload.sale.products.length) {
					resolve(payload.sale);
				}
				else if (status === 200 && payload && payload.basket_change === true && payload.messages && Object.keys(payload.messages).length > 0) {
					reject(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	get: (saleID) => {
		return new Promise((resolve, reject) => {
			request.get(`sale/${saleID}`, false, function (payload, status, request) {
				if (status === 200 && payload && payload.sale) {
					resolve(payload.sale);
					// resolve({
					// 	...payload.sale,
					// 	summary: {
					// 		...payload.sale.summary,
					// 		services: [
					// 			...payload.sale.summary.services,
					// 			{
					// 				name: "Puan Kullanımı",
					// 				price: {original: -30, original_str: "-30", whole: -30, whole_str: "-30" },
					// 				service_code: "PUAN"
					// 			}
					// 		]
					// 	}
					// });
				}
				else {
					reject(request);
				}
			});
		});
	},
	getSummary: (saleID, saleCode) => {
		return new Promise((resolve, reject) => {
			request.get(`sale/summary/${saleID}/${saleCode}`, false, function (payload, status, request) {
				if (status === 200 && payload && payload) {
					try {
						resolve({
							...payload,
							delivery: {
								...payload.delivery,
								delivery_mobile_number: payload.delivery.delivery_mobile_number ? formatPhone(payload.delivery.delivery_mobile_number) : payload.delivery.delivery_mobile_number,
							},
							invoice: {
								...payload.invoice,
								id_number: payload.invoice.id_number === config.undefinedTaxNumber ? '' : payload.invoice.id_number,
								invoice_tax_number: payload.invoice.invoice_tax_number === config.undefinedTaxNumber ? '' : payload.invoice.invoice_tax_number,
								invoice_tax_office: payload.invoice.invoice_tax_office === config.undefinedTaxOffice ? '' : payload.invoice.invoice_tax_office,
							}
						});
					}
					catch(e) {
						console.warn('Hata', e);
						reject(request);
					}
				}
				else {
					reject(request);
				}
			});
		});
	},
	setAddresses: (saleID, deliveryID, invoiceID) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleID}`,
				{
					member_delivery_id: deliveryID, 
					member_invoice_id: invoiceID,
				},
				function (payload, status, request) {
					if (status === 200) {
						resolve({
							'sale-id': saleID,
							'delivery-id': deliveryID, 
							'invoice-id': invoiceID
						});
					}
					else {
						reject(request);
					}
				}
			);
		});
	},
	setCargo: (saleID, cargoID) => {
		return new Promise((resolve, reject) => {
			request.post(
				`cargoes/set-cargo`,
				{
					cargo_id: cargoID,
					sale_id: saleID,
				},
				function (payload, status, request) {
					if (status === 200) {
						resolve(cargoID);
					}
					else {
						reject(request);
					}
				}
			);
		});
	},
	getCargoes: (saleID) => {
		return new Promise((resolve, reject) => {
			request.get(`cargoes/${saleID}`, false, function (payload, status, request) {
				if (status === 200 && payload && payload.cargoes) {
					resolve(payload.cargoes.map(c => ({ ...c, value: c.id })));
				}
				else {
					reject(request);
				}
			});
		});
	},
	getInstallments: (saleID, bin, total) => {
		return new Promise((resolve, reject) => {
			request.post(
				`payment/installments`,
				{
					bin_number: bin,
					total: total,
					sale_id: saleID,
				},
				function (payload, status, request) {
				if (status === 200 && payload && payload.bank && payload.installments) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	setNote: (saleID, note) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleID}/set-note`,
				{
					sale_note: note.replace(/(\r\n|\n|\r)/gm, ' '),
				},
				function (payload, status, request) {
					if (status === 200) {
						resolve(note);
					}
					else {
						reject(request);
					}
				}
			);
		});
	},
	checkStoreStock: (saleID, storeID) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleID}/store-check`,
				{
					store_id: storeID,
				},
				function (payload, status, request) {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	setStore: (saleID, storeID) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${saleID}/store-set`,
				{
					store_id: storeID,
				},
				function (payload, status, request) {
				if (status === 200 && payload) {
					resolve(payload);
				}
				else {
					reject(request);
				}
			});
		});
	},
	getPaymentAccounts: () => {
		return new Promise((resolve, reject) => {
			request.get(`payment/bank-accounts`, false, function (payload, status, request) {
				if (status === 200 && payload && payload.bank_accounts) {
					resolve(payload.bank_accounts);
				}
				else {
					reject(request);
				}
			});
		});
	},
	rebateSaleProduct: (orderID, formData) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${orderID}/rebate`,
				{ rebate: formData },
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'İade işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	cancelOrder: (orderID) => {
		return new Promise((resolve, reject) => {
			request.post(
				`sale/${orderID}/cancel`,
				false,
				function (payload, status, request) {
					if (status === 200) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'İptal işlemi gerçekleştirilirken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				},
				{ });
		});
	},
	// CompleteSale
	completeSaleByCreditCard: (paymentInfo) => {
		return new Promise((resolve, reject) => {
			request.post(
				`payment/credit-card`,
				paymentInfo,
				function (payload, status, request) {
				// Basket Changed Check
				if (status === 200 && payload && payload.basket_change === true && payload.messages && Object.keys(payload.messages).length > 0) {
					reject(payload);
				}
				else if (status === 200 && payload && payload.is_3d && payload.content) {
					document.open();
					document.write(payload.content);
					document.close();
				}
				else if (status === 200 && payload && !payload.is_3d && request.data.meta?.messages?.error && request.data.meta?.messages?.error.length === 0) {
					resolve(payload);
				}
				else {
					reject(request.data.meta ? request.data.meta.messages : 'Siparişiniz tamamlanırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
				}
			});
		});
	},
	completeSaleByBankTransfer: (paymentInfo) => {
		return new Promise((resolve, reject) => {
			request.post(
				`payment/bank-transfer`,
				paymentInfo,
				function (payload, status, request) {
					if (status === 200 && payload && payload.basket_change === true && payload.messages && Object.keys(payload.messages).length > 0) {
						reject(payload);
					}
					else if (status === 200 && payload && payload.sale_code && payload.sale_id) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Siparişiniz tamamlanırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				}
			);
		});
	},
	completeSaleByDoorPayment: (paymentInfo) => {
		return new Promise((resolve, reject) => {
			request.post(
				`payment/door-pay`,
				paymentInfo,
				function (payload, status, request) {
					if (status === 200 && payload && payload.sale_code && payload.sale_id) {
						resolve(payload);
					}
					else {
						reject(request.data.meta ? request.data.meta.messages : 'Siparişiniz tamamlanırken bir hata ile karşılaşıldı, lütfen daha sonra tekrar deneyin.');
					}
				}
			);
		});
	},
	// Loyalty
	getLoyaltyPoints: (saleId) => {
		return new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve({
					points: 24600.6,
				});
			}, 1000)
		});
	},
}

export default saleServices;