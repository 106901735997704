import config from '/config';

// Deps
import { useState, useEffect, useCallback, useRef, useMemo } from 'react'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact'
import parseAnalyticsProduct from './functions/parse-analytics-product'

// Context
import { useAuth } from '@wearetla/tla-essentials-tools/utilities/auth';
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useNavigator } from '@wearetla/tla-essentials-tools/utilities/navigator';
import { AnalyticsContext } from './';
import { useGlobalEvents } from '@wearetla/tla-essentials-tools/utilities/global-events';
import { useBasket } from '/controllers/basket';

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce'

export const AnalyticsProvider = ({ children }) => {
	const [listingData, setListingData] = useState(false);
	const debouncedListingData = useDebounce(listingData, 500);
	const { initialized: authInitialized, userData } = useAuth();
	const { addEventHook, removeEventHook } = useGlobalEvents();
	const { activeRoute } = useNavigator();
	const { products: basketProducts, summary: basketSummary } = useBasket();
	const { isApplication, clientOS } = useGlobalState();
	const match = useMemo(() => activeRoute?.match ?? false, [activeRoute]);

	const activeRouteRef = useRef(false);
	const activeMatch = useRef(false);
	const activeUserData = useRef(null);
	const activeBasketProducts = useRef([]);
	const activeBasketSummary = useRef(null);

	const sendGtagEvent = useCallback((rawProps = {}) => {
		if(window.gtag && config.gtagID) {
			try {				
				const props = {
					products: [],
					event: 'page_view',
					value: decodeURIComponent(window.location.pathname),
					sendTo: config.gtagID,
					params: {},
					includeBasketContent: true,
					pageType: activeRouteRef.current?.key,
					...rawProps,
				}
			
				let eventData = {
					'send_to': props.sendTo,
					'value': props.value,
					'items': props.products,
					'currency': 'TRY',
					'page_type': props.pageType,
					...props.params,
					'user': props.user ? parseUserData(props.user) : activeUserData.current,
				};
	
				if(isApplication && clientOS) {
					eventData.device_os = clientOS;
				}

				if(props.includeBasketContent) {
					eventData.basket_content = {
						currency: 'TRY',
						line_items: (activeBasketProducts.current ?? []).map((product => ({
							product: parseAnalyticsProduct(product),
							quantity: product.basket_quantity,
							subtotal: product.total_price?.original,
						}))),
						total: activeBasketSummary.current?.grand_total?.original ?? 0,
					};
				}

				// console.info('Gtag Event: ' + props.event, eventData);
				window.gtag('event', props.event, eventData);
			}
			catch(e) {
				console.warn('Analytics Error', e);
			}
		}
	}, [clientOS, isApplication]);

	const sendAnalyticsEvent = useCallback((props = {}) => {
		if(config.gaID) {
			sendGtagEvent({
				sendTo: config.gaID,
				...props,
			})
		}
	}, [sendGtagEvent]);


	const updateAnalyticsListingData = ({ key, products, params = {}}) => {
		if(config.gtagID) {
			setListingData({
				key: key,
				products: products ? products.map((p, nth) => (
					parseAnalyticsProduct(p, { list_name: key, list_position: nth + 1, ...params })
				)) : []
			});
		}
	}

	const productAddEvent = ({ product, quantity }) => {
		sendAnalyticsEvent({
			event: 'add_to_cart',
			value: product.price.original,
			products: [parseAnalyticsProduct({
				...product,
				// serial_title: selectedSerial ? selectedSerial.title : false,
			}, {
				category: (product.category_breadcrumb && product.category_breadcrumb.length > 0) ? product.category_breadcrumb[product.category_breadcrumb.length - 1].name : false,
				quantity: quantity,
			})],
		});
	}
	
	const productRemoveEvent = ({ product, quantity }) => {
		sendAnalyticsEvent({
			event: 'remove_from_cart',
			value: product.price.original,
			products: [parseAnalyticsProduct({
				...product,
			}, {
				quantity: quantity,
			})],
		});
	}

	const initializeScripts = () => {
		const headElem = document.querySelector('head');

		if(config.gtmID) {
			const gtmScript = document.createElement('script');
			gtmScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0], j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${config.gtmID}');`;

			headElem.appendChild(gtmScript);
		}
		if(config.gtagID) {
			const gtagScript = document.createElement('script');
			gtagScript.setAttribute('async', true);
			gtagScript.setAttribute('onLoad', `const gtagLoadEvent = new CustomEvent('gtagReady'); window.dispatchEvent(gtagLoadEvent);`);
			gtagScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${config.gtagID}`);

			const gtagScript2 = document.createElement('script');
			gtagScript2.innerHTML = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${config.gtagID}');`;

			headElem.appendChild(gtagScript);
			headElem.appendChild(gtagScript2);
		}
		if(config.gaID) {
			const gaScript = document.createElement('script');
			gaScript.setAttribute('async', true);
			gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${config.gaID}`);
			
			const gaScript2 = document.createElement('script');
			gaScript2.innerHTML = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag('js', new Date());gtag('config', '${config.gaID}');`

			headElem.appendChild(gaScript);
			headElem.appendChild(gaScript2);
		}
		if(config.pixelID) {
			const pixelScript = document.createElement('script');
			pixelScript.innerHTML = `!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '${config.pixelID}');fbq('set','agent','tmgoogletagmanager', '${config.pixelID}');fbq('track', "PageView");`;
			
			const pixelNoScript = document.createElement('noscript');
			pixelNoScript.innerHTML = `<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=${config.pixelID}&ev=PageView&noscript=1" />`;

			headElem.appendChild(pixelScript);
			headElem.appendChild(pixelNoScript);
		}
	}

	const handleUrlChange = useCallback((match) => {
		if(match && !isExact(activeMatch.current, match)) {
			sendAnalyticsEvent({
				event: 'page_view',
				params: {
					url: window.location.origin + match.url,
					type: activeRoute?.key,
					params: match.params,
					title: activeRoute?.head ? activeRoute?.head.title : activeRoute?.fullKey,
				}
			});
			sendAnalyticsEvent({
				event: 'route_change',
				params: {
					url: window.location.origin + match.url,
					params: match.params,
					title: activeRoute?.head ? activeRoute?.head.title : activeRoute?.fullKey,
				}
			});
			activeMatch.current = match;
		}
	}, [activeRoute, sendAnalyticsEvent])

	useEffect(() => {
		activeRouteRef.current = activeRoute;
	}, [activeRoute])

	const parseUserData = useCallback((userData) => {
		return userData ? {
			full_name: userData.full_name,
			name: userData.name,
			surname: userData.surname,
			uuid: userData.id?.toString?.(),
			gdpr_optin: userData.terms,
			email_optin: false, // userData.notification_email,
			sms_optin: false, // userData.notification_sms,
			// email: userData.email, // KVKK sebebiyle eklenmiyor.
			// phone_number: userData.mobile_number_formatted, // KVKK sebebiyle eklenmiyor.
		} : null;
	}, [])

	const handleUserDataChange = useCallback(() => {
		const parsedUserData = parseUserData(userData)

		if(authInitialized && !isExact(activeUserData.current, parsedUserData)) {
			activeUserData.current = parsedUserData;
			sendAnalyticsEvent({
				event: 'update_user',
			})
		}

	}, [userData, sendAnalyticsEvent, authInitialized, parseUserData])

	useEffect(() => {
		activeBasketProducts.current = basketProducts;
		activeBasketSummary.current = basketSummary;
	}, [basketProducts, basketSummary])
	
	useEffect(() => {
		initializeScripts();

		addEventHook('productBasketAdd', productAddEvent);
		addEventHook('productBasketRemove', productRemoveEvent);
		addEventHook('listingDataUpdate', updateAnalyticsListingData);
		
		return () => {
			removeEventHook('productBasketAdd', productAddEvent);
			removeEventHook('productBasketRemove', productRemoveEvent);
			removeEventHook('listingDataUpdate', updateAnalyticsListingData);
		}
	}, []);
	
	useEffect(() => {
		handleUrlChange(match);
	}, [match, handleUrlChange])

	useEffect(() => {
		handleUserDataChange();
	}, [userData, handleUserDataChange])
	
	useEffect(() => {
		if(debouncedListingData) {
			sendGtagEvent({
				event: 'update_listing_products',
				products: debouncedListingData.products
			});
		}
	}, [debouncedListingData, sendGtagEvent]);

	return (
		<AnalyticsContext.Provider value={{
			sendGtagEvent,
			sendAnalyticsEvent,
		}}>
			{children}
		</AnalyticsContext.Provider>
	)
}