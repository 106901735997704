import config from '/config';

// Functions
import parseIsoCurrency from '/functions/parse-iso-currency';

export const parseAnalyticsProduct = (rawProduct = {}, additionalParams = {}) => {
	const product = {
		...rawProduct, 
		...additionalParams,
	}

	let analyticsProduct = {
		'id': product.serial_id?.toString?.(),
		'google_business_vertical': 'retail',
		"name": product.product_name,
		"brand": product.brand,
		"taxonomy": product.category_breadcrumb?.length ? product.category_breadcrumb.map(crumb => crumb.name) : (product.category ? [product.category] : []),
		"variant": (product.serial_title && product.serial_title.length > 0 && product.serial_title !== '-') ? product.serial_title : '-',
		"price": product.price.original_str,
		"unit_price": (product.old_price ?? product.price).original,
		"unit_sale_price": product.price.original,
		"currency": parseIsoCurrency(product.price.currency),
		"url": `${config.siteURL}/${product.slug}-p-${product.id}`,
		"product_image_url": (product?.images?.[0] ? product.image_types.mini + product.images[0].url : false),
		...additionalParams,
	}

	// if(product.position) {
	// 	analyticsProduct.list_postion = product.position;
	// }
	// if(product.basketQuantity) {
	// 	analyticsProduct.quantity = product.quantity;
	// }
	// if(product.listName) {
	// 	analyticsProduct.list_name = product.listName
	// }
	// if(product.category) {
	// 	analyticsProduct.category = product.category
	// }

	return analyticsProduct;
}

export default parseAnalyticsProduct;